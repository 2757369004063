<script>
    export default {
        name: "MyCardSimple",
        data() {
            return { 
                'Dane':{
                    'Obraz': '',
                    'Tytul': '',
                    'Text': '',
                    'LinkImg': '',
                    'LinkText': '',
                    'LinkSciezka': '',
                    'LinkZewnetrznyText': '',
                    'LinkZewnetrznySciezka': '',
                    'LinkDownloadText': '',
                    'LinkDownloadSciezka': ''
                },
                'Pokaz': false
            }
        },
        props: {
            Id: {
                type: Number,
                default: 0
            }
        },
        methods:{
            OdczytajApi(){
                if (this.Id > 0) {
                    document.MojeApiGET("/www/cardSimple", this.PowrotApi, {'Id': this.Id})
                }
            },
            PowrotApi(param){
                // console.debug('MyCardSimple: ', param)
                this.Dane = param
                this.Pokaz = true
            }
        },
        created(){
            window.setTimeout(this.OdczytajApi, 100)
        }
    }
</script>

<template>
    <div>
        <div v-if="Pokaz">
            <!-- <div class="card" style="border-bottom: none;"> -->
            <div class="card">
                <div class="KreskiGora">
                </div>
                <div class="PrzejscieGora">
                </div>
                <div class="MarginesIkonki">
                    <div align="left">
                        <a v-if="Dane.LinkImg" v-bind:href="Dane.LinkImg">
                            <img   class="Obrazek" v-bind:src="Dane.Obraz" v-bind:alt="Dane.Tytul">
                        </a>
                        <img v-else-if="Dane.Obraz"  class="Obrazek" v-bind:src="Dane.Obraz" v-bind:alt="Dane.Tytul">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title tytulmoj" align="right"><b>{{ Dane.Tytul }}</b></h5>
                        
                        <p v-if="Dane.Text" class="card-text" align="right">{{ Dane.Text }}</p>
                        <!-- Link Wewnętrzny -->
                        <div v-if="Dane.LinkText && Dane.LinkSciezka" align="right">
                            <h6> 
                                <b-link v-bind:href="Dane.LinkSciezka">{{ Dane.LinkText }}</b-link>
                            </h6>
                        </div>
                        <!-- Link Zewnętrzny -->
                        <div v-if="Dane.LinkZewnetrznyText && Dane.LinkZewnetrznySciezka" align="right">
                            <h6> 
                                <b-link v-bind:href="Dane.LinkZewnetrznySciezka" target="_blank">{{ Dane.LinkZewnetrznyText }}</b-link>
                            </h6>
                        </div>
                        <!-- Download -->
                        <div v-if="Dane.LinkDownloadText && Dane.LinkDownloadSciezka" align="right">
                            <h6> 
                                <b-link v-bind:href="Dane.LinkDownloadSciezka" download>{{ Dane.LinkDownloadText }}</b-link>
                            </h6>
                        </div>
                    </div>
                </div>
                <!-- <div class="PrzejscieDola"> -->
                <!-- </div> -->
                <div class="zobaczwiecej" align="center">
                    <a v-if="Dane.LinkImg" v-bind:href="Dane.LinkImg">
                        <img  src="@/assets/zobaczwiecejsimple.png" alt="">
                    </a>
                </div>
            </div>
            <div class="card border-0">
                <!-- <div class="PrzejscieDol"> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<style scoped>
.Obrazek {
    max-width: 80%;
    max-height: 8rem;
    /* aspect-ratio: 1; */
}
.zobaczwiecej {
    margin-bottom: 25px;
}
.tytulmoj {
    font-style: italic; 
    background: -webkit-linear-gradient(-80deg, rgb(201, 27, 27) 30%, rgb(0, 0, 0));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.KreskiGora {
    /* background-color: black; */
    background-image: linear-gradient(90deg, rgb(201, 27, 27) 60%, rgb(0, 0, 0) 100%);
    height: 2px;
    margin-bottom: 3px;
}
.MarginesIkonki {
    padding-left: 20px;
}
.PrzejscieGora {
    /* background-color: blue; */
    /* border-top: 3px; */
    /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 82%, rgb(170, 170, 230) 100%); */
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 13%, rgb(170, 170, 230) 100%);
    padding-top: 50px;
}

.test000Bot {
    /* background-color: black; */
    background-image: linear-gradient(90deg, rgb(255, 255, 255) 30%, rgb(231, 27, 27) 100%);
    height: 1px;
    margin-top: 1px;
}
.PrzejscieDol {
    /* background-color: #0004ff; */
    height: 30px;
    background-image: linear-gradient(0deg, rgb(250, 250, 250) 0%, rgb(230, 230, 255) 100%);
    /* background-image: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(235, 235, 255) 100%); */
}
.PrzejscieDola {
    /* background-color: #0004ff; */
    height: 25px;
    background-image: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(230, 230, 255) 100%);
    /* background-image: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(235, 235, 255) 100%); */
}
</style>
